<script setup>
import Menubar from 'primevue/menubar';
import { useTabsStore } from '@/stores/tabs';
import { useAuthStore } from '../stores/auth';
import gql from 'graphql-tag';
import { useLazyQuery } from '@vue/apollo-composable';



const NewCollection = markRaw( defineAsyncComponent( () => import( './NewCollection.vue' ) ) );
const AccountLookup = markRaw( defineAsyncComponent( () => import( './AccountLookup.vue' ) ) );
const MergeAccounts = markRaw( defineAsyncComponent( () => import( './MergeAccounts.vue' ) ) );
const CreateBusinessAccount = markRaw( defineAsyncComponent( () => import( './CreateBusinessAccount.vue' ) ) );
const CreateCustomerAccount = markRaw( defineAsyncComponent( () => import( './CreateCustomerAccount.vue' ) ) );
const ProductLookup = markRaw( defineAsyncComponent( () => import( './ProductLookup.vue' ) ) );
const MergeProducts = markRaw( defineAsyncComponent( () => import( './MergeProducts.vue' ) ) );
const Sales = markRaw( defineAsyncComponent( () => import( './Sales.vue' ) ) );
const Reports = markRaw( defineAsyncComponent( () => import( './Reports.vue' ) ) );
const ServiceOpeners = markRaw( defineAsyncComponent( () => import( './ServiceOpeners.vue' ) ) );
const Promos = markRaw( defineAsyncComponent( () => import( './Promos.vue' ) ) );
const WhatsNew = markRaw( defineAsyncComponent( () => import( './WhatsNew.vue' ) ) );
const Seasons = markRaw( defineAsyncComponent( () => import( './Seasons.vue' ) ) );
const ProductActionQueue = markRaw( defineAsyncComponent( () => import( './ProductActionQueue.vue' ) ) );
const PurchaseAgreements = markRaw( defineAsyncComponent(() => import('./PurchaseAgreements.vue')));
const AFTP = markRaw( defineAsyncComponent( () => import( './AFTP.vue' ) ) );
const ReportPremiumDownloads = markRaw( defineAsyncComponent( () => import( './ReportPremiumDownloads.vue' ) ) );
const ReportSalesByType = markRaw( defineAsyncComponent( () => import( './ReportSalesByType.vue' ) ) );
const ReportRoyaltiesDue = markRaw( defineAsyncComponent( () => import( './ReportRoyaltiesDue.vue' ) ) );
const ReportRoyaltiesSummary = markRaw( defineAsyncComponent( () => import( './ReportRoyaltiesSummary.vue' ) ) );
const ReportSubscriptionCancellations = markRaw( defineAsyncComponent( () => import( './ReportSubscriptionCancellations.vue' ) ) );
const ReportChurn = markRaw( defineAsyncComponent( () => import( './ReportingChurn.vue' ) ) );
const ReportAACRoyalties = markRaw( defineAsyncComponent( () => import( './ReportAACRoyalties.vue' ) ) );
const ReportingSalesTax = markRaw( defineAsyncComponent( () => import( './ReportingSalesTax.vue' ) ) );
const FraudComputers = markRaw( defineAsyncComponent( () => import( './FraudComputers.vue' ) ) );

const authStore = useAuthStore();
const GET_CLAIMS = gql`
query Query {
  roles
}
`;

/*Possible roles:
Admin,
Money,
Nuke,
PriceOverride,
Reporting,
UsePreviousCreditCard
*/

const { result: claims, load: loadClaims, onError: onClaimsFailed, refetch: refetchClaims } = useLazyQuery( GET_CLAIMS, null, { clientId: 'security' } );

const loggedIn = computed( () => authStore.loggedIn );
const userRoles = ref( [] );

watch( loggedIn, async ( val ) =>
{
    if ( val )
    {
        loadClaims();
    }
} );



const tabStore = useTabsStore();

const items = ref( [
    {
        label: 'Accounts',
        icon: 'pi pi-fw pi-user',
        items: [
            {
                label: 'Account Lookup',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Account Lookup',
                        component: AccountLookup
                    } );

                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Merge Accounts',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Merge Accounts',
                        component: MergeAccounts
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );

                }
            },
            {
                label: 'New Business Account',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'New Business Account',
                        component: CreateBusinessAccount
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );

                }
            },
            {
                label: 'New Customer Account',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'New Customer Account',
                        component: CreateCustomerAccount
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );

                }
            },
        ]
    },
    {
        label: 'Products',
        icon: 'pi pi-fw pi-tags',
        items: [
            {
                label: 'AFTP',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'AFTP',
                        component: AFTP
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'New Collection',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'New Collection',
                        component: NewCollection
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Product Action Queue',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Product Action Queue',
                        component: ProductActionQueue
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Product Lookup',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Product Lookup',
                        component: ProductLookup
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Sales',
                command: () =>
                {
                    // tabStore.addTab( {
                    //     id: tabStore.generateId(),
                    //     name: 'Sales',
                    //     component: Sales
                    // } );
                    // tabStore.setActiveTab( tabStore.tabs.length - 1 );
                    alert( "NO." );
                }
            }
        ]
    },
    {
        label: 'Reports',
        icon: 'pi pi-fw pi-chart-bar',
        disabled: ()=>{return !(authStore.getRoles().indexOf("Reporting") > -1)},
        items: [
            {
                label: 'AAC Royalties',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'AAC Royalties',
                        component: ReportAACRoyalties,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Account Totals',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Account Totals',
                        component: ReportChurn,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Premium Downloads',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Premium Downloads',
                        component: ReportPremiumDownloads,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Royalties Due',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Royalties Due',
                        component: ReportRoyaltiesDue,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Royalties Summary',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Royalties Summary',
                        component: ReportRoyaltiesSummary,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Sales By Type',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Sales By Type',
                        component: ReportSalesByType,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Sales Tax',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Sales Tax',
                        component: ReportingSalesTax,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Subscription Cancellations',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Subscription Cancellations',
                        component: ReportSubscriptionCancellations,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            }
        ]
    },
    {
        label: 'Admin',
        disabled: ()=>{return !(authStore.getRoles().indexOf("Administrator") > -1)},
        icon: 'pi pi-fw pi-cog',
        items: [
            {
                label: 'Fraud Computers',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Fraud Computers',
                        component: FraudComputers
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },    
            {
                label: 'Promo Codes',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Promo Codes',
                        component: Promos,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Purchase Agreements',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Purchase Agreements',
                        component: PurchaseAgreements
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Seasons',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Seasons',
                        component: Seasons,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'Service Openers',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'Service Openers',
                        component: ServiceOpeners,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            },
            {
                label: 'What\'s New',
                command: () =>
                {
                    tabStore.addTab( {
                        id: tabStore.generateId(),
                        name: 'What\'s New',
                        component: WhatsNew,
                    } );
                    tabStore.setActiveTab( tabStore.tabs.length - 1 );
                }
            }
        ]
    },
    {
        label: ()=>{return loggedIn.value ? 'Logout' : 'Login'},
        icon: ()=>{return loggedIn.value ? 'pi pi-fw pi-sign-out' : 'pi pi-fw pi-sign-in'},
        command: () =>
        {
            if ( loggedIn.value){
                authStore.logout();
            } else {
                authStore.login();
            }
        }
    },
] );

const tabClass = computed( () =>
{
    if ( tabStore.chandler )
    {
        return 'chandler-header';
    }
    if ( tabStore.nolan )
    {
        return 'nolan-header';
    }
    return '';
} )

watch(claims, () => {
    if (claims.value?.roles?.length > 0) {
        userRoles.value = claims.value.roles;
    }
})

//kick people out if they don't have AmpUser role
watch(userRoles, () => {
    if (userRoles.value.indexOf("AmpUser") === -1) {
        alert("Claims Passed, no AmpUser role")
        authStore.logout();
    }
})

onClaimsFailed( ( err ) =>
{
    alert("Claims Failed")
    authStore.logout();
});

//TODO
const isProducer = computed( () =>
{
    return authStore.getRoles().indexOf('ProducerAccountAdmin') > -1
} );

</script>

<template>
    <Menubar :model="items" class="header" :class="tabClass" v-if="!isProducer"/>
    
</template>

<style lang="scss" scoped>
.nolan-header {
    background-image: url('../assets/nolan.png') !important;
    background-position: 0%;
    background-attachment: fixed;
}

.chandler-header {
    background-image: url('../assets/chandler.jpg') !important;
    background-position: -70% 540px;
    background-attachment: fixed;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 20px;
}

:deep(.p-menuitem-link) {
    text-align: start;
}

:deep(.p-submenu-list) {
    width: fit-content !important;
    white-space: nowrap;
}
</style>
